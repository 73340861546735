export default class ToolsbarComponent {
    constructor() {
        ToolsbarComponent.langSwitcher()
    }

    static langSwitcher() {
        const current_lang = $('.tools__item__languages__current')
        const list_langs = $('.tools__item__languages__langs')
        const list_lang_item = $('.tools__item__languages__langs > .gtranslate_wrapper > a')
        const medium_list_lang_item = $(
            '.menu__tools__item__languages__langs > .gtranslate_wrapper > a',
        )
        const medium_current_lang = $('.menu__tools__item__languages__current')
        const medium_list_langs = $('.menu__tools__item__languages__langs')

        //Open submenu
        current_lang.on('click', () => {
            list_langs.toggleClass('tools__item__languages__langs--open')
        })

        //Hide submenu on outside click
        $(window).on('click', (e) => {
            if (!$(e.target).hasClass('tools__item__languages__current')) {
                if ($(list_langs).hasClass('tools__item__languages__langs--open')) {
                    $(list_langs).removeClass('tools__item__languages__langs--open')
                }
            }

            if (!$(e.target).hasClass('menu__tools__item__languages__current')) {
                if ($(medium_list_langs).hasClass('menu__tools__item__languages__langs--open')) {
                    $(medium_list_langs).removeClass('menu__tools__item__languages__langs--open')
                }
            }
        })

        //Select lang
        list_langs.on('click', () => {
            list_langs.toggleClass('tools__item__languages__langs--open')
        })

        //Change Header Language on click
        list_lang_item.on('click', function () {
            list_lang_item.show()
            $(this).hide()
            current_lang.text($(this).text())
        })

        if (window.matchMedia('(max-width: 1000px)').matches) {
            //Open submenu on mobile
            medium_current_lang.on('click', () => {
                medium_list_langs.toggleClass('menu__tools__item__languages__langs--open')
            })

            //Select lang on mobile
            medium_list_langs.on('click', () => {
                medium_list_langs.toggleClass('menu__tools__item__languages__langs--open')
            })

            //Change Header Language on click
            medium_list_lang_item.on('click', function () {
                medium_list_lang_item.show()
                $(this).hide()
                medium_current_lang.text($(this).text())
            })

            //Init select lang
            $(window).on('load', () => {
                setTimeout(() => {
                    const current_language = $('html').attr('lang').replace(/-.*!/gi, '')
                    current_lang.text(current_language)

                    //Mobile remove current language on list (init)
                    medium_list_lang_item.each(function () {
                        if ($(this).text() === currentLang.toUpperCase()) {
                            $(this).hide()
                        }
                    })

                    $('.menu__tools__item__languages__current').html(currentLang.toUpperCase())
                }, 300)
            })
        }

        // Fix current lang
        let currentLang = document.getElementsByTagName('html')[0].getAttribute('lang')
        if (currentLang.length > 2) {
            currentLang = currentLang.split('-')[0]
        }

        //Init select lang
        $(window).on('load', () => {
            setTimeout(() => {
                const current_language = $('html').attr('lang').replace(/-.*!/gi, '')
                current_lang.text(current_language)

                //Remove current language on list (init)
                list_lang_item.each(function () {
                    if ($(this).text() === currentLang.toUpperCase()) {
                        $(this).hide()
                    }
                })

                $('.tools__item__languages__current').html(currentLang.toUpperCase())
            }, 300)
        })
    }
}
