export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.itemHover()
    }

    static itemHover() {
        const $submenuParent = $('.has-submenu')
        const $body = $('body')

        $submenuParent.on('mouseenter', () => {
            $body.addClass('submenu-hover')
        })

        $submenuParent.on('mouseleave', () => {
            $body.removeClass('submenu-hover')
        })
    }

    static sticky() {
        let lastScrollTop = 0

        $(window).on('load scroll', function () {
            const body = $('body')
            const offset = 85 //Trigger offset (~header height)
            const navbar = $('[data-id="wpiris-navbar"]')
            const scrollTop = $(this).scrollTop()
            let full = false

            //Hidden comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                body.addClass('header-is-hidden')
            } else {
                body.removeClass('header-is-hidden')
            }

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                full = true
            }

            lastScrollTop = scrollTop

            if (scrollTop > offset) {
                navbar.removeClass('navbar--ontop')
                body.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
                body.addClass('navbar--onscroll')
                if (full === true) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else {
                navbar.removeClass('navbar--onscroll')
                body.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')
                body.addClass('navbar--ontop')
                if (full === true) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $('[data-id="wpiris-logo"]')
        let logoBanner
        let logoClassic

        if ($('body').hasClass('espace-pro')) {
            logoBanner = IRISCollectionCustomer.imageEspaceProLogoBanner
            logoClassic = IRISCollectionCustomer.imageEspaceProLogo
        } else if ($('body').hasClass('sainte-croix-du-verdon')) {
            logoBanner = IRISCollectionCustomer.imageVerdonLogoBanner
            logoClassic = IRISCollectionCustomer.imageVerdonLogo
        } else if ($('body').hasClass('blanche-serre-poncon')) {
            logoBanner = IRISCollectionCustomer.imageSerrePonconLogoBanner
            logoClassic = IRISCollectionCustomer.imageSerrePonconLogo
        } else if (
            $('body').hasClass('digne-les-bains') &&
            !$('body').hasClass('sainte-croix-du-verdon')
        ) {
            logoBanner = IRISCollectionCustomer.imageLogoBanner
            logoClassic = IRISCollectionCustomer.imageLogo
        }

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static burger() {
        const body = $('body')
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'
        const menuItems = '[data-trigger="wpiris-menu-item-has-submenu"]'
        const submenuClose = '[data-trigger="wpiris-submenu-close"]'
        const hasTouch = 'ontouchstart' in window

        //Trigger when touch screen
        if (hasTouch) {
            $(menuItems).each(function () {
                const itemElem = $(this)

                itemElem.children('.menu__item__link').on('click', (e) => {
                    e.preventDefault()
                    itemElem.find('.menu__item__submenu').addClass('submenu-open')
                    body.addClass('submenu-open')
                })
            })
        }

        if ($(submenuClose).length) {
            $(submenuClose).on('click', () => {
                body.removeClass(['menu-open', 'submenu-open'])
            })
        }

        //Open/Close menu
        $(trigger).on('click', () => {
            body.toggleClass('menu-open')
            $('.eco-bar').addClass('eco-bar--hidden')

            if (body.hasClass('submenu-open')) {
                body.removeClass('submenu-open')
            }

            if ($(submenu).hasClass('submenu-open')) {
                $(submenu).removeClass('submenu-open')
            }

            //Change logo on mobile
            if (window.matchMedia('(max-width: 650px)').matches) {
                if (body.hasClass('menu-open')) {
                    NavbarComponent.changeLogoColor('classic')
                } else {
                    if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
                        NavbarComponent.changeLogoColor('banner')
                    }
                }
            }
        })

        $(prev).on('click', (e) => {
            e.preventDefault()
            $(submenu).removeClass('submenu-open')
            body.removeClass('submenu-open')
        })
    }
}
