import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

import Utils from '../services/utils.service'

export default class BannerComponent {
	constructor() {
		this.bannerSlider().then()
	}

	async bannerSlider() {
		const selector = '#header-banner-slider'
		const options = {
			effect: 'fade',
			fadeEffect: {
				crossFade: true,
			},
			grabCursor: true,
			autoplay: {
				delay: 5000,
				disableOnInteraction: true,
			},
			speed: 2000,
			lazy: {
				loadPrevNext: true,
			},
			// If we need pagination
			pagination: {
				el: '[data-slider-pagination="banner"]',
				type: 'custom',
				renderCustom: (swiper, current, total) => {
					if (current < 10 || total < 10) {
						current = `0${current}`
						total = `0${total}`
					}

					return `
						<div class="header__content__slider-navigation__pagination__number">${current}</div>
                		<div class="header__content__slider-navigation__pagination__separator"></div>
                		<div class="header__content__slider-navigation__pagination__number">${total}</div>`
				},
			},
			// Navigation arrows
			navigation: {
				nextEl: '#banner-slider-button-next',
				prevEl: '#banner-slider-button-prev',
			},
		}

		Utils.swiperWrapper(selector, 'div:not(.header__banner__scdv-title-logo)') // ":not(.header__banner__scdv-title-logo)" -> pour le logo du banner sur Sainte-Croix du Verdon

		await Swiper.create('#header-banner-slider', options)
	}
}
